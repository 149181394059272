import styled from '@emotion/styled';
import { theme } from './GlobalStyles';
import { ReactComponent as ArrowShort } from '../../images/svgs/arrow-short.svg';
import { Link } from 'gatsby';

interface PrimaryProps {
    fill?: boolean;
}

export const ButtonPrimary = styled.button<PrimaryProps>`
    background: ${({ fill }) => (fill ? `${theme.colors.orange3}` : `none`)};
    color: ${({ fill }) => (fill ? `${theme.colors.gray1}` : `${theme.colors.orange3}`)};
    border: ${({ fill }) =>
        fill ? `2px solid ${theme.colors.orange3}` : `2px solid ${theme.colors.orange3}`};
    font-size: 18px;
    border-radius: 100px;
    font-weight: 500;
    padding: 18px 31px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s ease-in;
    font-weight: 600;
    cursor: pointer;
    svg {
        width: 18px;
        transition: 0.2s ease-in;
        margin-right: 12px;
        fill: ${({ fill }) => (fill ? `${theme.colors.gray1}` : `${theme.colors.orange3}`)};
    }

    @media ${({ theme }) => theme.device.tablet} {
        &:hover {
            transform: translateY(-2px);
            background: ${({ fill }) => (fill ? `none` : `${theme.colors.orange3}`)};
            color: ${({ fill }) => (fill ? `${theme.colors.orange3}` : `${theme.colors.gray1}`)};
            svg {
                fill: ${({ fill }) => (fill ? `${theme.colors.orange3}` : `${theme.colors.gray1}`)};
            }
        }
    }
`;

interface Color {
    color: 'orange' | 'white';
}

const StyledSecondaryButton = styled.button<Color>`
    border: none;
    cursor: pointer;
    text-transform: capitalize;
    background: none;
    line-height: 24px;
    padding-left: 0px;
    a {
        text-decoration: none;
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 18px;
        color: ${({ color }) => color === 'orange' && `${theme.colors.orange3}`};
        color: ${({ color }) => color === 'white' && `${theme.colors.gray1}`};
    }
    svg {
        fill: ${({ color }) => color === 'orange' && `${theme.colors.orange3}`};
        fill: ${({ color }) => color === 'white' && `${theme.colors.gray1}`};
        width: 16px;
    }
    div {
        margin-left: 16px;
        padding: 10px;
        display: flex;
        transition: 0.2s ease-in;
        align-items: center;
        justify-content: center;
        border-radius: 200px;
        transform: rotate(-45deg);
        border: ${({ color }) => color === 'orange' && `2px solid ${theme.colors.orange3}`};
        border: ${({ color }) => color === 'white' && `2px solid ${theme.colors.gray1}`};
    }
    &:hover {
        div {
            transform: rotate(0deg);
            transform: translateX(2px);
        }
    }
`;

interface Props {
    children: string | React.ReactNode;
    color: 'orange' | 'white';
    className?: string;
    link: string;
}
export const SecondaryButton = ({ children, color, link }: Props) => {
    return (
        <StyledSecondaryButton color={color}>
            <Link to={link}>
                {children}
                <div>
                    <ArrowShort />
                </div>
            </Link>
        </StyledSecondaryButton>
    );
};
