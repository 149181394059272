import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

export const TitleStyled = styled.section`
    color: ${({ theme }) => theme.colors.orange1};
    img {
        width: 40px;
    }
    h2 {
        margin: 8px 0 24px;
    }
    @media ${({ theme }) => theme.device.tablet} {
        img {
            width: 60px;
        }
        h2 {
            margin-top: 24px;
        }
    }
    @media ${({ theme }) => theme.device.laptop} {
        img {
            width: 80px;
        }
    }
`;

interface Props {
    title: string;
    white?: boolean;
    image: 'sun' | 'brush' | 'rocket' | 'review' | 'coffee' | 'location' | 'calendar';
    className?: string;
}

export const Title: React.FC<Props> = ({ className, white, title, image }) => {
    return (
        <TitleStyled className={className}>
            {image === 'sun' && (
                <StaticImage
                    placeholder="none"
                    quality={80}
                    width={80}
                    src="../images/homepage/sun.png"
                    alt="sun"
                />
            )}
            {image === 'brush' && (
                <StaticImage
                    placeholder="none"
                    quality={80}
                    width={80}
                    src="../images/homepage/paint-brush.png"
                    alt="paint brush"
                />
            )}
            {image === 'rocket' && (
                <StaticImage
                    placeholder="none"
                    quality={80}
                    width={80}
                    src="../images/homepage/rocket.png"
                    alt="rocket"
                />
            )}
            {image === 'review' && (
                <StaticImage
                    placeholder="none"
                    quality={80}
                    width={80}
                    src="../images/homepage/review.png"
                    alt="review"
                />
            )}
            {image === 'coffee' && (
                <StaticImage
                    placeholder="none"
                    quality={80}
                    width={80}
                    src="../images/homepage/coffee.png"
                    alt="coffee"
                />
            )}
            {image === 'location' && (
                <StaticImage
                    placeholder="none"
                    quality={80}
                    width={80}
                    src="../images/homepage/location.png"
                    alt="location"
                />
            )}
            {image === 'calendar' && (
                <StaticImage
                    placeholder="none"
                    quality={80}
                    width={80}
                    src="../images/homepage/calendar.png"
                    alt="calendar"
                />
            )}
            <h2 className={`${!white && 'gradient-fill'} header-2`}>{title}</h2>
        </TitleStyled>
    );
};
