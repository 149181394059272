import React, { useState } from 'react';
import styled from '@emotion/styled';
import { theme } from './GlobalStyles';
import { Title } from '@/Title';
import { ButtonPrimary, SecondaryButton } from './StyledComponents';
import { information } from 'information';
import patientForms from '../../patient-forms.pdf';
import { StaticImage } from 'gatsby-plugin-image';

export const RequestAppointmentStyled = styled.section`
    padding-bottom: 0px;

    .large-container {
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }
    .title {
        align-self: start;
        width: 200px;
    }

    @media ${({ theme }) => theme.device.tablet} {
    }
    @media ${({ theme }) => theme.device.laptop} {
        padding-top: 72px;
        .title {
            flex-shrink: 0;
            width: 300px;
            margin-top: 24px;
            order: 1;
        }
        .large-container {
            display: flex;
            align-items: start;
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: wrap;
        }
    }
`;
const Form = styled.form`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    .button-container {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        width: 100%;
        button + button {
            align-self: end;
            width: auto;
            margin-bottom: 24px;
        }
    }
    textarea {
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
    }
    input:not(input[type='checkbox']),
    textarea {
        border: none;
        outline: 2px solid ${theme.colors.gray4};
        background: none;
        padding: 15px 24px 15px 12px;
        transition: 0.2s ease-in;
        border-radius: 5px;
        margin-bottom: 10px;
    }
    input[type='checkbox'] {
        width: 16px;
        height: 16px;
        accent-color: ${({ theme }) => theme.colors.blue3};
    }

    button {
        width: 100%;
    }
    button:first-of-type {
        margin-bottom: 24px;
    }
    input:focus:not(input[type='checkbox']),
    textarea:focus {
        border: none;
        outline: 2px solid ${theme.colors.blue2};
    }
    input[type='date'] {
        border: none;

        padding: 15px 24px 15px 12px;
        transition: 0.2s ease-in;
        border-radius: 5px;
        border: none;
        width: 145px;
    }
    input[type='time'] {
        border: none;
        width: 145px;
        padding: 12px 24px 15px 12px;
        transition: 0.2s ease-in;
        border-radius: 5px;
    }

    ::-webkit-input {
        border: none;
        outline: 2px solid ${theme.colors.gray1};
        padding: 15px 24px 15px 12px;
        transition: 0.2s ease-in;
        border-radius: 5px;
    }

    label {
        font-weight: 500;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 163.5%;
        display: flex;
        flex-direction: column;
    }
    .flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    input:not(input[type='checkbox']),
    textarea {
        margin-top: 10px;
        margin-bottom: 16px;
    }
    textarea {
        margin-bottom: 24px;
        max-width: 345px;
    }
    ::-webkit-input-placeholder {
        color: inherit;
        opacity: 0.54;
    }

    div.phone-policy {
        display: flex;
        margin-bottom: 16px;
        gap: 6px;

        > input {
            flex-shrink: 0;
            margin-top: 3px;
        }

        > label {
            font-size: 14px;
            font-style: italic;
        }
    }
    @media ${theme.device.tablet} {
        textarea {
            margin-bottom: 24px;
            width: 100%;
            min-width: 698px;
        }
        input[type='date'],
        input[type='time'] {
            width: 314px;
        }
    }
    @media ${theme.device.laptop} {
        display: flex;
        flex-direction: column;

        order: 2;
        .button-container {
            flex-direction: row-reverse;
            justify-content: flex-start;
            gap: 32px;
            align-items: center;
            a {
                position: relative;
                top: 14px;
            }
            button:first-of-type {
                margin: 0;
                width: 250px;
                flex-shrink: 0;
            }
        }
        textarea {
            height: 92px;
            max-width: 541px;
            min-width: 541px;
        }
        input:not(input[type='checkbox']) {
            width: 541px;
            margin-bottom: 16px;
        }

        .flex {
            input[type='date'] {
                width: 190px;
            }
            input[type='time'] {
                width: 190px;
            }
        }

        div.phone-policy {
            > label {
                max-width: 520px;
            }
        }
    }
`;

const FirstVisitCard = styled.div`
    padding: 24px 16px;
    position: relative;
    border-radius: 15px;
    overflow: hidden;
    > div {
        width: 100%;
        position: absolute;
        z-index: 0;

        left: 0;
        top: 0;
        img {
            z-index: 0;
        }
    }
    > a {
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }
    button {
        z-index: 1;
        align-self: end;
    }
    p:first-of-type {
        z-index: 1;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: ${theme.colors.gray1};
        margin-bottom: 20px;
    }
    p:last-of-type {
        z-index: 1;
        color: ${theme.colors.gray1};
        margin-bottom: 28px;
    }
    @media ${theme.device.laptop} {
        order: 3;
        margin-top: -230px;
        width: 400px;
        height: 228px;
        padding: 32px;

        &:hover {
            outline: 2px solid ${theme.colors.gray1};
        }
        > a {
            justify-content: center;
        }
    }

    @media ${theme.device.laptopL} {
        width: 529px;
    }
`;

export const RequestAppointment: React.FC = () => {
    const [submitState, setSubmitState] = useState<string>();

    const [phoneNumber, setPhoneNumber] = useState('');

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onSubmit = async (e: any) => {
        e.preventDefault();
        setSubmitState('submitting');

        // important way for us to validate the form information and create a new object.
        //note the refuce function pushing the keys and values into the empty object dependency

        const formData = new FormData(e.target);
        const data = [...formData.keys()].reduce((obj, key) => {
            const value = formData.getAll(key).join(', ').trim();
            if (value) {
                obj[key] = value;
            }
            return obj;
        }, {} as Record<string, unknown>);

        await fetch(`https://api.${window.location.host}/contact`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        setSubmitState('submitted');

        e.target.reset();
    };
    return (
        <>
            <div style={{ position: 'relative', top: '-100px' }} id="contact"></div>
            <RequestAppointmentStyled>
                <div className="large-container">
                    <Title className="title" image="calendar" title="Request an Appointment" />
                    <Form onSubmit={onSubmit}>
                        <div>
                            <div>
                                <label htmlFor="name">
                                    Name *
                                    <input
                                        required
                                        id="name"
                                        name="name"
                                        type="text"
                                        placeholder="John Doe"
                                    />
                                </label>
                            </div>
                            <div>
                                <label htmlFor="email">
                                    Email address *
                                    <input
                                        required
                                        id="email"
                                        name="email"
                                        type="text"
                                        placeholder="johndoe@gmail.com"
                                    />
                                </label>
                            </div>
                            <div>
                                <label htmlFor="phone">
                                    Phone number
                                    <input
                                        id="phone"
                                        name="phone"
                                        type="tel"
                                        pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                        placeholder="123-456-7890"
                                        value={phoneNumber}
                                        onChange={e => setPhoneNumber(e.target.value)}
                                    />
                                </label>
                                <div className="phone-policy">
                                    <input
                                        type="checkbox"
                                        name="phonePolicy"
                                        id="phonePolicy"
                                        required={!!phoneNumber.length}
                                    />
                                    <label htmlFor="phonePolicy">
                                        By providing your phone number by phone or online, you are
                                        consenting to receive SMS text messages from Just For Kids
                                        Pediatric Dentistry. Msg & data rates may apply. You may
                                        reply STOP to opt out of messages.
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div>
                            <label htmlFor="message">Message</label>
                            <textarea id="message" name="name" />
                        </div>
                        <div className="button-container">
                            <ButtonPrimary
                                type="submit"
                                disabled={submitState === 'submitting'}
                                color="orange"
                                fill
                            >
                                {submitState === 'submitting'
                                    ? 'Sending Message...'
                                    : 'Message Sent!'}
                            </ButtonPrimary>
                            <SecondaryButton color="orange" link={`tel:${information.phone}`}>
                                Call Us Instead
                            </SecondaryButton>
                        </div>
                    </Form>

                    <FirstVisitCard>
                        <StaticImage
                            src="../../images/common/gradient-5.webp"
                            quality={80}
                            alt="gradient"
                        />
                        <a href={patientForms} target="__blank" rel="noopener noreferrer">
                            <p>First time Visit?</p>
                            <p>Let’s make things easy before you even step into our office. </p>
                            <SecondaryButton color="white" link="/online-forms/">
                                Fill out patient forms
                            </SecondaryButton>
                        </a>
                    </FirstVisitCard>
                </div>
            </RequestAppointmentStyled>
        </>
    );
};
