import { ReactComponent as Marker } from '../../images/svgs/marker.svg';
import { ReactComponent as Mobile } from '../../images/svgs/mobile.svg';
import { ReactComponent as Fax } from '../../images/svgs/fax.svg';
import { theme } from './GlobalStyles';

import styled from '@emotion/styled';
import { information } from 'information';

interface Props {
    color: string;
    className?: string;
}

const LocationLinkStyled = styled.div<Props>`
    .svg-container {
        background: ${({ color }) =>
            (color === 'orange' && theme.colors.orange3) ||
            (color === 'blue' && theme.colors.orange1)};
        padding: 8px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 36px;
        width: 36px;
    }

    min-width: 32px;
    height: 32px;
    border-radius: 5px;
    .mobile {
        width: 12px;
    }
    .fax {
        width: 15px;
    }
    .marker {
        height: 20px;
    }
    svg {
        fill: ${({ color }) =>
            (color === 'blue' && theme.colors.blue3) ||
            (color === 'orange' && theme.colors.gray1) ||
            (color === 'white' && theme.colors.gray1)};
    }

    a {
        line-height: 152.5%;
        transition: all 0.2s ease-in-out;
        text-decoration: underline;
        display: flex;
        font-size: 16px;
        color: ${({ theme, color }) =>
            color === 'white' ? theme.colors.gray1 : theme.colors.gray5};
        align-items: center;
        .marker-svg {
            width: 17px;
        }
        svg {
            transition: all 0.2s ease-in-out;
            transform: scale(1);
            min-width: 18px;
            fill: ${({ color }) =>
                (color === 'blue' && theme.colors.blue3) ||
                (color === 'orange' && theme.colors.gray1) ||
                (color === 'white' && theme.colors.gray1)};
        }
        span {
            margin-left: 17px;
        }
        &:hover {
            svg {
                transform: scale(1.1);
            }
        }
    }
`;

export const LocationLink: React.FC<{
    type: 'map' | 'phone' | 'fax';
    className?: string;
    border?: boolean;
    color: string;
}> = ({ type, className, color, border }) => {
    return (
        <LocationLinkStyled color={color} className={className}>
            {type === 'map' && (
                <a target="__blank" rel="noopener noreferrer" href={information.locationUrl}>
                    {border ? (
                        <div className="svg-container">
                            <Marker className="marker" />
                        </div>
                    ) : (
                        <Marker className="marker" />
                    )}
                    <span>{information.location}</span>
                </a>
            )}
            {type === 'phone' && (
                <a href={`tel:${information.phone}`}>
                    {border ? (
                        <div className="svg-container">
                            <Mobile className="mobile" />
                        </div>
                    ) : (
                        <Mobile className="mobile" />
                    )}
                    <span>{information.phone}</span>
                </a>
            )}
            {type === 'fax' && (
                <a href={`tel:${information.fax}`}>
                    {border ? (
                        <div className="svg-container">
                            <Fax className="fax" />
                        </div>
                    ) : (
                        <Fax className="fax" />
                    )}
                    <span>{information.fax}</span>
                </a>
            )}
        </LocationLinkStyled>
    );
};
