import React from 'react';
import styled from '@emotion/styled';
import { theme } from '@/layout/GlobalStyles';
import { LocationLink } from './Location-Link';
import { Title } from '@/Title';
import { RequestAppointment } from './Request-Appointment';

const SchedulePanel = styled.div`
    background: linear-gradient(146.77deg, #fffae2 2.24%, #dff5e6 55.11%, #c7edff 95.12%);
    padding: 128px 0 64px;
    iframe {
        height: 348.71px;
        margin-bottom: 24px;
        box-shadow: rgba(100, 100, 111, 0.7) 0px 7px 29px 0px;
        border-radius: 15px;
    }
    h2 {
        margin-bottom: 24px;
    }
    .link {
        font-weight: 500;
        margin-bottom: 24px;
        height: 30px;
    }
    .last-link {
        position: relative;
        bottom: 7px;
    }

    @media ${theme.device.tablet} {
        .top-container {
            justify-content: space-between;
            display: flex;
            gap: 32px;
        }
        iframe {
            width: 360px;
            height: 430px;
        }
    }
    @media ${theme.device.laptop} {
        padding: 180px 0 120px;
        .top-container {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: center;
        }

        iframe {
            width: 632px;
            margin-top: 32px;
            height: 633.79px;
        }
        .flex {
            max-width: 404px;
            display: block;
        }
    }
`;

const Hours = styled.div`
    margin: 0;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #3898ff;
    padding-top: 24px;
    ul {
        list-style: none;
        span {
            display: block;
            color: ${theme.colors.gray4};
        }
        li {
            margin: 0px 0px 16px;
        }
    }
    ul:first-of-type {
        font-weight: 700;

        text-align: left;
    }
    ul:last-of-type {
        text-align: right;
    }
    @media ${theme.device.tablet} {
    }
    @media ${theme.device.laptop} {
        width: 100%;
    }
`;

export const ContactUs = () => {
    return (
        <SchedulePanel>
            <div className="large-container top-container">
                <div>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2976.505430277923!2d-88.11578788907543!3d41.75275937313371!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880e57666dd95c0d%3A0x2e741d07c5b9091f!2sJust%20For%20Kids%20Pediatric%20Dentistry%3B%20Dr.%20Eryn%20%26%20Dr.%20Maribel!5e0!3m2!1sen!2sus!4v1689102084035!5m2!1sen!2sus"
                        width="100%"
                        loading="lazy"
                        title="Just For Kids Pediatric Dentistry"
                        height="100%"
                        frameBorder="0"
                        style={{ border: 0 }}
                        aria-hidden="false"
                    />
                </div>
                <div>
                    <Title image="location" title="Contact Us" />
                    <div className="flex">
                        <LocationLink className="link" border color="blue" type="map" />
                        <LocationLink className="link" color="blue" border type="phone" />
                        <LocationLink className="last-link link" color="blue" border type="fax" />
                        <Hours className="hours-container">
                            <ul>
                                <li>Monday</li>
                                <li>Tuesday</li>
                                <li>Wednesday</li>
                                <li>Thursday</li>
                                <li>Friday</li>
                                <li>
                                    <span>Saturday</span>
                                </li>
                                <li>
                                    <span>Sunday</span>
                                </li>
                            </ul>
                            <ul>
                                <li>8:00 AM - 1:30PM</li>
                                <li>9:00 AM - 6:30 PM</li>
                                <li>8:00 AM - 5:30 PM</li>
                                <li>8:00 AM - 5:30 PM</li>
                                <li>8:00 AM - 1:30 PM</li>
                                <li>
                                    <span>Closed</span>
                                </li>
                                <li>
                                    <span>Closed</span>
                                </li>
                            </ul>
                        </Hours>
                    </div>
                </div>
            </div>
            <RequestAppointment />
        </SchedulePanel>
    );
};
