import styled from '@emotion/styled';
import { theme } from '@/layout/GlobalStyles';
import { StaticImage } from 'gatsby-plugin-image';
import { LocationLink } from './Location-Link';
import { Link } from 'gatsby';
import { ReactComponent as Facebook } from '../../images/svgs/facebook.svg';
import { ReactComponent as Instagram } from '../../images/svgs/instagram.svg';

const FooterStyled = styled.footer`
    background: ${theme.colors.orange1};
    color: ${theme.colors.gray5};
    padding: 32px 0 16px;
    position: relative;
    .large-container {
        display: flex;
        flex-direction: column;
        margin-bottom: 32px;
        align-items: center;
    }
    span,
    a {
        color: ${theme.colors.gray5};
    }
    .location {
        text-decoration: underline;
    }
    h3 {
        font-weight: 700;
        color: ${theme.colors.blue3};
        margin-bottom: 24px;
    }

    .logo {
        width: 290px;
        margin-bottom: 40px;
    }
    svg {
        fill: ${theme.colors.gray1};
    }
    @media ${theme.device.tablet} {
        .main-flex {
            display: flex;
            gap: 64px;
        }
    }
    @media ${theme.device.laptop} {
        .large-container {
            margin-bottom: 0;
        }
        .main-flex {
            display: flex;
            gap: auto;
            width: 100%;
            justify-content: space-between;
            margin-bottom: 72px;
            .left-panel {
                position: relative;
                top: 80px;
            }
        }
        h3 {
            font-size: 24px;
            line-height: 35px;
            margin-bottom: 40px;
        }
        .logo {
            width: 403px;
        }
    }
`;

const ConnectUs = styled.div`
    align-self: start;

    div {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        max-width: 290px;
        height: 40px;
        span {
            position: relative;
            bottom: 8px;
        }
    }
`;

const Navigations = styled.div`
    align-self: start;
    a {
        transition: all 0.2s ease-in-out;
        &:hover {
            color: ${theme.colors.blue3};
        }
    }
    .list-flex {
        display: flex;
        gap: 37px;
        margin-bottom: 32px;

        ul {
            list-style: none;
        }
        li {
            margin-bottom: 24px;
        }
    }
    @media ${theme.device.laptop} {
        .list-flex {
            margin-bottom: 0;
        }
    }
`;

const FollowUs = styled.div`
    align-self: start;
    display: flex;
    gap: 32px;
    padding-bottom: 64px;
    .social-icon {
        height: 20px;
        fill: black;
        transition: all 0.2s ease-in-out;
        &:hover {
            fill: ${theme.colors.blue3};
        }
    }
    span {
        color: ${theme.colors.gray5};
    }
    @media ${theme.device.laptop} {
        padding-bottom: 128px;
        margin-bottom: 00px;
    }
`;

export const Footer: React.FC = () => {
    return (
        <FooterStyled>
            <div className="large-container">
                <StaticImage
                    className="logo"
                    src="../../images/common/logo-filled.jpg"
                    alt="first care dental logo"
                    placeholder="none"
                    quality={100}
                />
                <div className="main-flex">
                    <div className="left-panel">
                        <ConnectUs>
                            <LocationLink border color="orange" type="map" />
                            <LocationLink border color="orange" type="phone" />
                            <LocationLink border color="orange" type="fax" />
                        </ConnectUs>
                        <FollowUs>
                            <span>Follow Us:</span>
                            <a
                                aria-label="facebook logo"
                                target="__blank"
                                rel="noopener noreferrer"
                                href="https://www.facebook.com/justforkidsnaperville/"
                            >
                                <Facebook className="social-icon" role="img">
                                    <title>Facebook</title>
                                </Facebook>
                            </a>
                            <a
                                aria-label="instagram logo"
                                target="__blank"
                                rel="noopener noreferrer"
                                href="https://www.facebook.com/justforkidsnaperville/"
                            >
                                <Instagram className="social-icon" role="img">
                                    <title>Instagram</title>
                                </Instagram>
                            </a>
                        </FollowUs>
                    </div>
                    <Navigations>
                        <h3>Navigations</h3>
                        <div className="list-flex">
                            <ul>
                                <li>
                                    <Link to="/procedures/cleanings-prevention/dental-exams-cleanings/">
                                        Exams & Cleanings
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/procedures/cleanings-prevention/digital-x-rays/">
                                        Digital X-Rays
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/procedures/extractions/">Extractions</Link>
                                </li>
                                <li>
                                    <Link to="/procedures/restorations/composite-fillings/">
                                        Composite Fillings
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/privacy-policy/">Privacy Policy</Link>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <Link to="/cleanings-prevention/sealants/">Sealants</Link>
                                </li>
                                <li>
                                    {' '}
                                    <Link to="/procedures/limited-orthodontics/">
                                        Limited Orthodontics
                                    </Link>
                                </li>
                                <li>
                                    {' '}
                                    <Link to="/procedures/space-maintenance/">
                                        Space Maintenance
                                    </Link>
                                </li>
                                <li>
                                    {' '}
                                    <Link to="/procedures/restorations/zirconia-white-crowns/">
                                        Zirconia (White) Crowns{' '}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </Navigations>
                </div>
            </div>
            <p
                style={{
                    background: `#F9C67B`,
                    margin: '0 auto',
                    position: 'absolute',
                    bottom: '0',
                    width: '100%',
                    padding: '26px 0',
                    textAlign: 'center',
                    color: '#CF6515',
                }}
            >
                © {new Date().getFullYear()} JUST FOR KIDS PEDIATRIC DENTISTRY
            </p>
        </FooterStyled>
    );
};
