import { css, Global, Theme } from '@emotion/react';
import emotionNormalize from 'emotion-normalize';

type Size = {
    mobileS: string;
    mobileM: string;
    mobileL: string;
    tablet: string;
    tabletL: string;
    laptop: string;
    laptopL: string;
    desktop: string;
    desktopL: string;
};

const size: Size = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '400px',
    tablet: '630px',
    tabletL: '690px',
    laptop: '1030px',
    laptopL: '1280px',
    desktop: '1580px',
    desktopL: '1820px',
};

export const theme: Theme = {
    colors: {
        orange1: '#FFF1DC',
        orange2: '#F9C67B',
        orange3: '#ED7418',
        blue1: '#86C0FF',
        blue2: '#3898FF',
        blue3: '#0078E7',
        gray1: '#FCFCFC',
        gray4: '#85837A',
        gray5: '#1C1B1A',
    },
    device: {
        mobileS: `(min-width: ${size.mobileS})`,
        mobileM: `(min-width: ${size.mobileM})`,
        mobileL: `(min-width: ${size.mobileL})`,
        tablet: `(min-width: ${size.tablet})`,
        tabletL: `(min-width: ${size.tabletL})`,
        laptopS: `(min-width: ${size.tablet}) and (orientation:landscape)`,
        laptop: `(min-width: ${size.laptop}) and (orientation:landscape)`,
        laptopL: `(min-width: ${size.laptopL})`,
        desktop: `(min-width: ${size.desktop})`,
        desktopL: `(min-width: ${size.desktopL})`,
    },
};

export const GlobalStyles = () => {
    return (
        <Global
            styles={() => css`
                ${emotionNormalize};
                html,
                body {
                    scroll-behavior: smooth;
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                }
                body {
                    font-weight: normal;
                    font-size: 1rem;
                    word-wrap: break-word;
                    font-kerning: normal;
                    overflow-x: hidden;
                    word-wrap: break-word;
                    margin: 0;
                    font-family: 'Montserrat';
                    -webkit-font-smoothing: antialiased;
                }
                * {
                    box-sizing: border-box;
                }
                a {
                    text-decoration: none;
                    color: ${theme.colors.orange3};
                }
                .container,
                .large-container {
                    padding: 0px 16px;
                }
                ul {
                    padding: 0;
                }
                h1,
                h2,
                h3,
                h4,
                h5,
                h6,
                ul,
                li,
                p,
                button,
                div {
                    color: ${theme.colors.gray5};
                    margin: 0;
                }
                .text-center {
                    text-align: center;
                }
                .image,
                .copy-image {
                    background: url(image.png);
                    border-radius: 15px;
                }
                .copy {
                    p {
                        margin-bottom: 16px;
                    }
                }
                .link {
                    font-weight: 700;
                    text-decoration: none;
                    color: ${theme.colors.orange3};
                }
                .spec {
                    font-weight: bold;
                    color: ${theme.colors.orange3};
                }
                button {
                    border: none;
                    outline: none;
                    background: none;
                }
                .header {
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 152.5%;
                    color: ${theme.colors.gray5};
                }
                .header-2 {
                    font-style: normal;
                    font-size: 24px;
                    line-height: 29px;
                    text-transform: capitalize;
                }
                .gradient-fill {
                    background: linear-gradient(15deg, #33ceff 0%, #c344ff 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                }
                p,
                li {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 152.5%;
                    color: ${theme.colors.gray5};
                }
                @media ${theme.device.mobileL} {
                    .container,
                    .large-container {
                        padding: 0px 16px;
                    }
                }
                @media ${theme.device.tablet} {
                    .container,
                    .large-container {
                        max-width: 730px;
                        margin: 0 auto;
                        padding: 0 auto;
                    }
                    .header-2 {
                        font-weight: 700;
                        font-size: 36px;
                        line-height: 122.5%;
                    }
                }
                @media ${theme.device.laptop} {
                    .image,
                    .copy-image {
                        box-shadow: rgba(100, 100, 111, 0.7) 0px 7px 29px 0px;
                    }
                    .container {
                        max-width: 980px;
                    }
                    .large-container {
                        max-width: 1020px;
                    }
                    .header {
                        font-weight: 700;
                        font-size: 48px;
                        line-height: 152.5%;
                    }
                    .header-2 {
                        font-style: normal;
                        font-weight: 700;
                        font-weight: 700;
                        font-size: 40px;
                        line-height: 49px;
                    }
                }
                @media ${theme.device.laptopL} {
                    .container {
                        max-width: 1104px;
                        margin: 0 auto;
                    }
                    .large-container {
                        max-width: 1280px;
                    }
                }
                @media ${theme.device.desktop} {
                    .container {
                        max-width: 1280px;
                    }
                }
                @media ${theme.device.desktopL} {
                    .container {
                        margin: 0 auto;
                    }
                }
            `}
        />
    );
};
