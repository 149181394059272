export const navMenu = [
    {
        name: 'About Us',
        path: '/about-us/',
        menu: [
            {
                name: 'Our Practice',
                path: '/about-us/our-practice/',
            },
            { name: 'Meet Our Doctors', path: '/about-us/meet-our-doctors/' },
            { name: 'Meet The Team', path: '/about-us/meet-the-team/' },
            { name: 'Our Philosophy', path: '/about-us/our-philosophy/' },
            { name: 'Virtual Tour', path: '/about-us/virtual-tour/' },
        ],
    },
    {
        name: 'Procedures',
        path: '/procedures/',
        menu: [
            {
                name: 'Cleanings & Preventions',
                path: '/procedures/cleanings-prevention/',
                subMenu: [
                    {
                        name: 'Dental Exams & Cleanings',
                        path: '/procedures/cleanings-prevention/dental-exams-cleanings/',
                    },
                    {
                        name: 'Fluoride Treatment',
                        path: '/procedures/cleanings-prevention/fluoride-treatment/',
                    },
                    {
                        name: 'Sealants',
                        path: '/cleanings-prevention/sealants/',
                    },
                    {
                        name: 'Digital X-Rays',
                        path: '/procedures/cleanings-prevention/digital-x-rays/',
                    },
                    {
                        name: 'Oral Hygiene Aids',
                        path: '/procedures/cleanings-prevention/oral-hygiene-aids/',
                    },
                ],
            },
            {
                name: 'Restoration',
                path: '/procedures/restorations/',
                subMenu: [
                    {
                        name: 'Zirconia (White) Crowns',
                        path: '/procedures/restorations/zirconia-white-crowns/',
                    },
                    {
                        name: 'Composite Fillings',
                        path: '/procedures/restorations/composite-fillings/',
                    },
                    {
                        name: 'Anterior Strip Crowns',
                        path: '/procedures/restorations/anterior-strip-crowns/',
                    },
                    {
                        name: 'Pulp Therapy',
                        path: '/procedures/restorations/pulp-therapy/',
                    },
                    {
                        name: 'Stainless Steel Crowns',
                        path: '/procedures/restorations/stainless-steel-crowns',
                    },
                ],
            },
            {
                path: '',
                subMenu: [
                    {
                        name: 'Extractions',
                        path: '/procedures/extractions/',
                    },
                    {
                        name: 'Space Maintenance',
                        path: '/procedures/space-maintenance/',
                    },
                    {
                        name: 'Limited Orthodontics',
                        path: '/procedures/limited-orthodontics/',
                    },
                ],
            },
        ],
    },
    {
        name: 'Patient Resources',
        path: '/patient-resources/',
        menu: [
            {
                name: `Your Child's First Visit`,
                path: '/your-childs-first-visit/',
            },
            { name: 'Online Forms', path: '/online-forms/' },

            { name: 'Contact Us', path: '/contact-us/' },
        ],
    },
];

export const servicesMenu = [
    {
        name: 'General Dentistry',
        path: 'general-dentistry/',
        menu: [
            { name: 'Oral Cancer Screening', path: 'oral-dentistry/oral-cancer-screaning/' },
            { name: 'Same Day Crowns', path: 'general-dentistry/same-day-crowns/' },
            { name: 'Sealants', path: 'general-dentistry/sealants/ ' },
            { name: 'Saturday Dentist', path: 'general-dentistry/saturday-dentist/' },
            { name: 'Root Canals', path: 'general-dentistry/root-canals/' },
            { name: 'Home Care', path: 'general-dentistry/home-care/' },
            { name: 'Night Guard', path: 'general-dentistry/night-guard/ ' },
            { name: 'Teeth Extractions', path: 'general-dentistry/teeth-extractions/' },
            { name: 'Emergency Dentist', path: 'general-dentistry/emergency-dentist/ ' },
            { name: 'Sedation', path: 'sedation-dentistry/' },
            { name: 'Nitrous Oxide', path: 'sedation-dentistry/nitrous-oxide/' },
        ],
    },
    {
        name: 'Periodontics',
        path: 'periodontics/',
        menu: [
            { name: 'Periodontal Therapy', path: 'periodontal-therapy/periodontal-therapy/' },
            { name: 'Deep Teeth Cleaning', path: 'periodontics/deep-teeth-cleaning/' },
            { name: 'Soft Tissue Graft', path: 'periodontics/soft-tissue-graft/' },
            { name: 'LANAP Procedure', path: 'periodontics/lanap-procedure/' },
        ],
    },
    {
        name: 'Orthodontics',
        path: 'orthodontics/',
        menu: [
            { name: 'Ortho Screenings', path: 'orthodontics/ortho-screenings/' },
            { name: 'Invisalign', path: 'cosmetic-dentistry/invisalign/' },
        ],
    },
    {
        name: 'Cosmetic',
        path: 'cosmetic-dentistry/',
        menu: [
            { name: 'Teeth Whitening', path: 'cosmetic-dentistry/teeth-whitening/' },
            { name: 'Porcelain Veneers', path: 'cosmetic-dentistry/porcelain-veneers/   ' },
            { name: 'Onlays', path: 'cosmetic-dentistry/inlays-onlays/' },
            { name: 'Gum Contouring', path: 'cosmetic-dentistry/gum-contouring/' },
        ],
    },

    {
        name: 'Implants',
        path: 'dental-implants/',
        menu: [
            { name: 'Bone Grafting', path: 'oral-dentistry/bone-grafting/' },
            { name: 'Full / Partial Dentures', path: 'dental-implants/full-partial-dentures/' },
            { name: 'Dental Bridges', path: 'dental-implants/dental-bridges/' },
        ],
    },
    {
        name: 'Technology',
        path: 'technology/',
        menu: [
            { name: 'iTero', path: 'technology/itero-intraoral-scanners/' },
            { name: 'Cone Beam / 3D X-Ray', path: 'technology/3d-imaging/' },
            { name: 'Digital X-Rays', path: 'technology/digital-x-rays/' },
            { name: 'Soft Tissue Laser', path: 'technology/soft-tissue-laser/' },
            { name: 'Hard Tissue Laser', path: 'technology/hard-tissue-laser/' },
            { name: 'VELscope', path: 'technology/velscope/' },
        ],
    },
];
