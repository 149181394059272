exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-virtual-tour-tsx": () => import("./../../../src/pages/about-us/virtual-tour.tsx" /* webpackChunkName: "component---src-pages-about-us-virtual-tour-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-procedures-tsx": () => import("./../../../src/pages/procedures.tsx" /* webpackChunkName: "component---src-pages-procedures-tsx" */),
  "component---src-templates-alt-template-tsx-content-file-path-home-runner-work-just-for-kids-just-for-kids-src-content-general-about-us-index-mdx": () => import("./../../../src/templates/alt-template.tsx?__contentFilePath=/home/runner/work/just-for-kids/just-for-kids/src/content/general/about-us/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-tsx-content-file-path-home-runner-work-just-for-kids-just-for-kids-src-content-general-about-us-index-mdx" */),
  "component---src-templates-alt-template-tsx-content-file-path-home-runner-work-just-for-kids-just-for-kids-src-content-general-cleanings-and-preventions-index-mdx": () => import("./../../../src/templates/alt-template.tsx?__contentFilePath=/home/runner/work/just-for-kids/just-for-kids/src/content/general/cleanings-and-preventions/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-tsx-content-file-path-home-runner-work-just-for-kids-just-for-kids-src-content-general-cleanings-and-preventions-index-mdx" */),
  "component---src-templates-alt-template-tsx-content-file-path-home-runner-work-just-for-kids-just-for-kids-src-content-general-patient-resources-index-mdx": () => import("./../../../src/templates/alt-template.tsx?__contentFilePath=/home/runner/work/just-for-kids/just-for-kids/src/content/general/patient-resources/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-tsx-content-file-path-home-runner-work-just-for-kids-just-for-kids-src-content-general-patient-resources-index-mdx" */),
  "component---src-templates-alt-template-tsx-content-file-path-home-runner-work-just-for-kids-just-for-kids-src-content-general-restorations-index-mdx": () => import("./../../../src/templates/alt-template.tsx?__contentFilePath=/home/runner/work/just-for-kids/just-for-kids/src/content/general/restorations/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-tsx-content-file-path-home-runner-work-just-for-kids-just-for-kids-src-content-general-restorations-index-mdx" */),
  "component---src-templates-standard-template-tsx-content-file-path-home-runner-work-just-for-kids-just-for-kids-src-content-about-us-meet-our-doctors-index-mdx": () => import("./../../../src/templates/standard-template.tsx?__contentFilePath=/home/runner/work/just-for-kids/just-for-kids/src/content/about-us/meet-our-doctors/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-tsx-content-file-path-home-runner-work-just-for-kids-just-for-kids-src-content-about-us-meet-our-doctors-index-mdx" */),
  "component---src-templates-standard-template-tsx-content-file-path-home-runner-work-just-for-kids-just-for-kids-src-content-about-us-meet-the-team-index-mdx": () => import("./../../../src/templates/standard-template.tsx?__contentFilePath=/home/runner/work/just-for-kids/just-for-kids/src/content/about-us/meet-the-team/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-tsx-content-file-path-home-runner-work-just-for-kids-just-for-kids-src-content-about-us-meet-the-team-index-mdx" */),
  "component---src-templates-standard-template-tsx-content-file-path-home-runner-work-just-for-kids-just-for-kids-src-content-about-us-our-philosophy-index-mdx": () => import("./../../../src/templates/standard-template.tsx?__contentFilePath=/home/runner/work/just-for-kids/just-for-kids/src/content/about-us/our-philosophy/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-tsx-content-file-path-home-runner-work-just-for-kids-just-for-kids-src-content-about-us-our-philosophy-index-mdx" */),
  "component---src-templates-standard-template-tsx-content-file-path-home-runner-work-just-for-kids-just-for-kids-src-content-about-us-our-practice-index-mdx": () => import("./../../../src/templates/standard-template.tsx?__contentFilePath=/home/runner/work/just-for-kids/just-for-kids/src/content/about-us/our-practice/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-tsx-content-file-path-home-runner-work-just-for-kids-just-for-kids-src-content-about-us-our-practice-index-mdx" */),
  "component---src-templates-standard-template-tsx-content-file-path-home-runner-work-just-for-kids-just-for-kids-src-content-patient-resources-contact-us-index-mdx": () => import("./../../../src/templates/standard-template.tsx?__contentFilePath=/home/runner/work/just-for-kids/just-for-kids/src/content/patient-resources/contact-us/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-tsx-content-file-path-home-runner-work-just-for-kids-just-for-kids-src-content-patient-resources-contact-us-index-mdx" */),
  "component---src-templates-standard-template-tsx-content-file-path-home-runner-work-just-for-kids-just-for-kids-src-content-patient-resources-first-visit-index-mdx": () => import("./../../../src/templates/standard-template.tsx?__contentFilePath=/home/runner/work/just-for-kids/just-for-kids/src/content/patient-resources/first-visit/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-tsx-content-file-path-home-runner-work-just-for-kids-just-for-kids-src-content-patient-resources-first-visit-index-mdx" */),
  "component---src-templates-standard-template-tsx-content-file-path-home-runner-work-just-for-kids-just-for-kids-src-content-patient-resources-new-patient-info-index-mdx": () => import("./../../../src/templates/standard-template.tsx?__contentFilePath=/home/runner/work/just-for-kids/just-for-kids/src/content/patient-resources/new-patient-info/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-tsx-content-file-path-home-runner-work-just-for-kids-just-for-kids-src-content-patient-resources-new-patient-info-index-mdx" */),
  "component---src-templates-standard-template-tsx-content-file-path-home-runner-work-just-for-kids-just-for-kids-src-content-procedures-anterior-strip-crown-index-mdx": () => import("./../../../src/templates/standard-template.tsx?__contentFilePath=/home/runner/work/just-for-kids/just-for-kids/src/content/procedures/anterior-strip-crown/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-tsx-content-file-path-home-runner-work-just-for-kids-just-for-kids-src-content-procedures-anterior-strip-crown-index-mdx" */),
  "component---src-templates-standard-template-tsx-content-file-path-home-runner-work-just-for-kids-just-for-kids-src-content-procedures-composite-fillings-index-mdx": () => import("./../../../src/templates/standard-template.tsx?__contentFilePath=/home/runner/work/just-for-kids/just-for-kids/src/content/procedures/composite-fillings/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-tsx-content-file-path-home-runner-work-just-for-kids-just-for-kids-src-content-procedures-composite-fillings-index-mdx" */),
  "component---src-templates-standard-template-tsx-content-file-path-home-runner-work-just-for-kids-just-for-kids-src-content-procedures-dental-exams-cleanings-index-mdx": () => import("./../../../src/templates/standard-template.tsx?__contentFilePath=/home/runner/work/just-for-kids/just-for-kids/src/content/procedures/dental-exams-cleanings/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-tsx-content-file-path-home-runner-work-just-for-kids-just-for-kids-src-content-procedures-dental-exams-cleanings-index-mdx" */),
  "component---src-templates-standard-template-tsx-content-file-path-home-runner-work-just-for-kids-just-for-kids-src-content-procedures-digital-x-rays-index-mdx": () => import("./../../../src/templates/standard-template.tsx?__contentFilePath=/home/runner/work/just-for-kids/just-for-kids/src/content/procedures/digital-x-rays/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-tsx-content-file-path-home-runner-work-just-for-kids-just-for-kids-src-content-procedures-digital-x-rays-index-mdx" */),
  "component---src-templates-standard-template-tsx-content-file-path-home-runner-work-just-for-kids-just-for-kids-src-content-procedures-extractions-index-mdx": () => import("./../../../src/templates/standard-template.tsx?__contentFilePath=/home/runner/work/just-for-kids/just-for-kids/src/content/procedures/extractions/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-tsx-content-file-path-home-runner-work-just-for-kids-just-for-kids-src-content-procedures-extractions-index-mdx" */),
  "component---src-templates-standard-template-tsx-content-file-path-home-runner-work-just-for-kids-just-for-kids-src-content-procedures-fluoride-treatment-index-mdx": () => import("./../../../src/templates/standard-template.tsx?__contentFilePath=/home/runner/work/just-for-kids/just-for-kids/src/content/procedures/fluoride-treatment/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-tsx-content-file-path-home-runner-work-just-for-kids-just-for-kids-src-content-procedures-fluoride-treatment-index-mdx" */),
  "component---src-templates-standard-template-tsx-content-file-path-home-runner-work-just-for-kids-just-for-kids-src-content-procedures-limited-orthodontics-index-mdx": () => import("./../../../src/templates/standard-template.tsx?__contentFilePath=/home/runner/work/just-for-kids/just-for-kids/src/content/procedures/limited-orthodontics/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-tsx-content-file-path-home-runner-work-just-for-kids-just-for-kids-src-content-procedures-limited-orthodontics-index-mdx" */),
  "component---src-templates-standard-template-tsx-content-file-path-home-runner-work-just-for-kids-just-for-kids-src-content-procedures-oral-hygeiene-aids-index-mdx": () => import("./../../../src/templates/standard-template.tsx?__contentFilePath=/home/runner/work/just-for-kids/just-for-kids/src/content/procedures/oral-hygeiene-aids/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-tsx-content-file-path-home-runner-work-just-for-kids-just-for-kids-src-content-procedures-oral-hygeiene-aids-index-mdx" */),
  "component---src-templates-standard-template-tsx-content-file-path-home-runner-work-just-for-kids-just-for-kids-src-content-procedures-properly-brush-and-floss-index-mdx": () => import("./../../../src/templates/standard-template.tsx?__contentFilePath=/home/runner/work/just-for-kids/just-for-kids/src/content/procedures/properly-brush-and-floss/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-tsx-content-file-path-home-runner-work-just-for-kids-just-for-kids-src-content-procedures-properly-brush-and-floss-index-mdx" */),
  "component---src-templates-standard-template-tsx-content-file-path-home-runner-work-just-for-kids-just-for-kids-src-content-procedures-pulp-therapy-index-mdx": () => import("./../../../src/templates/standard-template.tsx?__contentFilePath=/home/runner/work/just-for-kids/just-for-kids/src/content/procedures/pulp-therapy/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-tsx-content-file-path-home-runner-work-just-for-kids-just-for-kids-src-content-procedures-pulp-therapy-index-mdx" */),
  "component---src-templates-standard-template-tsx-content-file-path-home-runner-work-just-for-kids-just-for-kids-src-content-procedures-sealants-index-mdx": () => import("./../../../src/templates/standard-template.tsx?__contentFilePath=/home/runner/work/just-for-kids/just-for-kids/src/content/procedures/sealants/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-tsx-content-file-path-home-runner-work-just-for-kids-just-for-kids-src-content-procedures-sealants-index-mdx" */),
  "component---src-templates-standard-template-tsx-content-file-path-home-runner-work-just-for-kids-just-for-kids-src-content-procedures-space-maintenance-index-mdx": () => import("./../../../src/templates/standard-template.tsx?__contentFilePath=/home/runner/work/just-for-kids/just-for-kids/src/content/procedures/space-maintenance/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-tsx-content-file-path-home-runner-work-just-for-kids-just-for-kids-src-content-procedures-space-maintenance-index-mdx" */),
  "component---src-templates-standard-template-tsx-content-file-path-home-runner-work-just-for-kids-just-for-kids-src-content-procedures-stainless-steel-crowns-index-mdx": () => import("./../../../src/templates/standard-template.tsx?__contentFilePath=/home/runner/work/just-for-kids/just-for-kids/src/content/procedures/stainless-steel-crowns/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-tsx-content-file-path-home-runner-work-just-for-kids-just-for-kids-src-content-procedures-stainless-steel-crowns-index-mdx" */),
  "component---src-templates-standard-template-tsx-content-file-path-home-runner-work-just-for-kids-just-for-kids-src-content-procedures-zirconia-crowns-index-mdx": () => import("./../../../src/templates/standard-template.tsx?__contentFilePath=/home/runner/work/just-for-kids/just-for-kids/src/content/procedures/zirconia-crowns/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-tsx-content-file-path-home-runner-work-just-for-kids-just-for-kids-src-content-procedures-zirconia-crowns-index-mdx" */)
}

